import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { production } from '@env';
import { initLanguage } from '@blockframes/utils/i18n/init';
import { preferredLanguage } from '@blockframes/model';

if (production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

initLanguage(preferredLanguage(), { loadTranslations: false });